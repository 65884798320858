<template>
  <div>
    <PageHeaderLayout>
      <div class="main-page-content" v-loading="loadingStaus">
        <div class="notice_title_box">
          <div @click="showSysNoticeDetail(item.id, i)" v-for="(item, i) in dataList" :key="i + 'i'">
            <el-badge :class="articleId == item.id ? 'is-active' : 'false'" :is-dot="!item.is_read"
                      class="item">
              <div :title="item.title" class="notice_title">
                {{ item.title }}
              </div>
            </el-badge>
          </div>
        </div>
        <div class="notice_main_box">
          <div class="htmls" v-html="info.content"></div>
        </div>
      </div>
    </PageHeaderLayout>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import {mapGetters} from 'vuex'

export default {
  name: 'NoticeList',
  computed: {
    ...mapGetters(['userInfo']),
  },
  components: {PageHeaderLayout},
  data() {
    return {
      dataList:[
      ],
      info: '',
      articleId: '1',
      loadingStaus: false,
    }
  },
  created() {
    this.getList()
  },
  mounted() {

    // this.xx(this.isdata.data.list[0].id)
    // this.articleId = this.isdata.data.list[0].id
  },
  methods: {
    async getDetail(id) {
      let {info} = await this.$api.getSysNoticeInfo(id)
      this.info = info
      this.loadingStaus = false
    },
    showSysNoticeDetail(id, i) {
      this.articleId = id
      this.getDetail(id)
      this.readSysNotice(id,i)

    },

    async readSysNotice(id,i){
      this.dataList[i].is_read=1
      //发送请求
      await this.$api.readSysNoticeInfo(this.userInfo.id,id)
    },
    async getList() {
      //
      this.loadingStaus = true
      let {list} = await this.$api.getSysNoticeList()
      this.dataList = list
      this.loadingStaus = false

    }
  },
}
</script>

<style lang="scss" scoped>
.notice_title_box {
  float: left;
  width: 200px;
  min-height: calc(100vh - 120px);
  overflow-y: scroll;
  border-right: 1px solid #eaeefb;
  border-radius: 10px;
  margin: 10px 20px;
  padding: 0;

  > * {
    padding: 10px;
  }

  > div .el-badge {
    height: 40px;
    // border: 1px solid red;
    font-size: 16px;
    text-align: center;
    width: 98%;
    line-height: 40px;
    border-bottom: 1px solid #eaeefb;
    margin-top: 12px;
    cursor: pointer;

    // border: 1px solid red;
  }

  > div > .is-active {
    // background-color: #eaeefb;
    color: rgb(255, 49, 118);
  }

  .notice_title {
    width: 98%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.notice_main_box {
  display: flex;
  float: left;
  width: calc(100% - 340px);
  // border: 1px solid blue;
  overflow: scroll;
  max-height: 83vh;
  margin: 10px 20px;
  border-radius: 10px;
  min-height: calc(100vh - 120px);
  padding: 0;

  > * {
    padding: 20px;
  }
}
</style>
